const Footer = () => {
  return (
    <>
      <footer>
        <p>
          Shamrock Auto Trim - Custom Automotive Interiors since 1968
        </p>
      </footer>
    </>
  );
};

export default Footer;
